import { Box, Button, useMediaQuery, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { shades } from '../theme'

const timerStyle = {
  display: 'flex',
  flexDirection: 'row',
  fontWeight: 'bold',
}

const buttonStyle = {
  color: 'white',
  backgroundColor: shades.secondary[500],
  fontSize: '18px',
  fontWeight: 'bold',
  margin: '6px 26px'
}

const Timer = () => {
  const isNonMobile = useMediaQuery('(min-width:900px)')
  const [countdown, setCountdown] = useState(getTimeUntilNextSunday())

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(getTimeUntilNextSunday())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  function getTimeUntilNextSunday() {
    // Get the current date and time
    const now = new Date()

    // Get the date for tomorrow
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0)

    // Calculate the time difference in milliseconds
    const timeDifference = tomorrow - now
    return formatTime(timeDifference)
  }

  function formatTime(ms) {
    const seconds = Math.floor((ms / 1000) % 60)
    const minutes = Math.floor((ms / 1000 / 60) % 60)
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24)

    return { hours, minutes, seconds }
  }

  return (
    <Box
      display="flex"
      flexDirection={isNonMobile ? 'row' : 'column'}
      style={{ userSelect: 'none' }}
      width="100%"
      margin="20px auto"
      alignItems="center"
      justifyContent="center"
      color={shades.secondary[500]}
    >
      <Typography fontSize={isNonMobile ? 30 : 20} fontWeight="bold" style={{ marginRight: isNonMobile ? '16px' : '' }}>
        До кінця розпродажу залишилось:
      </Typography>
      <Box
        style={timerStyle}
        color={shades.secondaryRed[400]}
        mt={isNonMobile ? 0 : 3}
        minWidth="300px"
        justifyContent="center"
      >
        <Typography fontSize={40} fontWeight="bold">
          {countdown.hours}
        </Typography>
        <Typography fontSize={12} fontWeight="bold" pt={1}>
          Годин
        </Typography>
        <Typography fontSize={40} fontWeight="bold" pl={2}>
          {countdown.minutes}
        </Typography>
        <Typography fontSize={12} fontWeight="bold" pt={1}>
          Хвилин
        </Typography>
        <Typography fontSize={40} fontWeight="bold" pl={2}>
          {countdown.seconds}
        </Typography>
        <Typography fontSize={12} fontWeight="bold" pt={1}>
          Секунд
        </Typography>
        {/* {isNonMobile ? 
        <a
            href="https://rdr.salesdoubler.com.ua/in/offer/1310?aid=103422&dlink=https%3A%2F%2Fparfumcity.com.ua%2Fua"
            target="_self"
            rel="noopener noreferrer"
          >
            <Button id="catalog-link-btn" variant="contained" style={buttonStyle}>
              КАТАЛОГ
            </Button>
          </a>:<></>} */}
      </Box>
    </Box>
  )
}

export default Timer
