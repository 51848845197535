import { Box, Typography, useMediaQuery } from '@mui/material'
import { shades } from '../theme'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import HandshakeIcon from '@mui/icons-material/Handshake'
import WhatshotIcon from '@mui/icons-material/Whatshot'

function Details() {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  return (
    <Box marginTop="0" padding="10px 0 " style={{ userSelect: 'none', backgroundColor: 'white' }}>
      <Box
        maxWidth={1200}
        width="100%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap="10px"
        padding={2}
      >
        <Box width={isNonMobile ? 'clamp(22%, 22%, 22%)' : 'clamp(45%, 45%, 45%)'}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <LocalShippingIcon fontSize="large" style={{ width: 40, color: shades.secondary[500] }} />
            <Typography
              fontSize={isNonMobile ? 16 : 13}
              fontWeight="bold"
              mb="0"
              textAlign="center"
              color={shades.secondary[500]}
            >
              Безкоштовна доставка
            </Typography>
            <Typography fontSize={isNonMobile ? 12 : 10} fontWeight="bold" mb="10px" textAlign="center">
              При замовленні 2х і більше одиниць
            </Typography>
          </Box>
          {/* </Box> */}
        </Box>
        <Box width={isNonMobile ? 'clamp(22%, 22%, 22%)' : 'clamp(45%, 45%, 45%)'}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CreditCardIcon fontSize="large" style={{ width: 40, color: shades.secondary[500] }} />
            <Typography
              fontSize={isNonMobile ? 16 : 13}
              fontWeight="bold"
              mb="0"
              textAlign="center"
              color={shades.secondary[500]}
            >
              Оплата при отриманні
            </Typography>
            <Typography fontSize={isNonMobile ? 12 : 10} fontWeight="bold" mb="10px" textAlign="center">
              Ніяких додаткових платежів
            </Typography>
          </Box>
          {/* </Box> */}
        </Box>
        <Box width={isNonMobile ? 'clamp(22%, 22%, 22%)' : 'clamp(45%, 45%, 45%)'}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <HandshakeIcon fontSize="large" style={{ width: 40, color: shades.secondary[500] }} />
            <Typography
              fontSize={isNonMobile ? 16 : 13}
              fontWeight="bold"
              mb="0"
              textAlign="center"
              color={shades.secondary[500]}
            >
              Нам довіряють
            </Typography>
            <Typography fontSize={isNonMobile ? 12 : 10} fontWeight="bold" mb="10px" textAlign="center">
              Більше 880 000 клієнтів
            </Typography>
          </Box>
          {/* </Box> */}
        </Box>
        <Box width={isNonMobile ? 'clamp(22%, 22%, 22%)' : 'clamp(45%, 45%, 45%)'}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <WhatshotIcon fontSize="large" style={{ width: 40, color: shades.secondary[500] }} />
            <Typography
              fontSize={isNonMobile ? 16 : 13}
              fontWeight="bold"
              mb="0"
              textAlign="center"
              color={shades.secondary[500]}
            >
              Знижки
            </Typography>
            <Typography fontSize={isNonMobile ? 12 : 10} fontWeight="bold" mb="10px" textAlign="center">
              Регулярні акції та знижки
            </Typography>
          </Box>
          {/* </Box> */}
        </Box>
        {/* <Box width={isNonMobile ? 'clamp(22%, 22%, 22%)' : 'clamp(45%, 45%, 45%)'}>
          <Box display="flex">
            {isNonMobile ? (
              <CreditCardIcon fontSize="large" style={{ width: 40, marginRight: 16, color: shades.secondary[500] }} />
            ) : (
              ''
            )}

            <Box>
              <Typography fontSize={16} fontWeight="bold" mb="0" color={shades.secondary[500]}>
                Оплата при отриманні
              </Typography>
              <Typography fontSize={12} fontWeight="bold" mb="10px">
                Ніяких додаткових платежів
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box width={isNonMobile ? 'clamp(22%, 22%, 22%)' : 'clamp(45%, 45%, 45%)'}>
          <Box display="flex">
            {isNonMobile ? (
              <HandshakeIcon fontSize="large" style={{ width: 40, marginRight: 16, color: shades.secondary[500] }} />
            ) : (
              ''
            )}

            <Box>
              <Typography fontSize={16} fontWeight="bold" mb="0" color={shades.secondary[500]}>
                Нам довіряють
              </Typography>
              <Typography fontSize={12} fontWeight="bold" mb="10px">
                Більше 880 000 клієнтів
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box width={isNonMobile ? 'clamp(22%, 22%, 22%)' : 'clamp(45%, 45%, 45%)'}>
          <Box display="flex">
            {isNonMobile ? (
              <WhatshotIcon fontSize="large" style={{ width: 40, marginRight: 16, color: shades.secondary[500] }} />
            ) : (
              ''
            )}

            <Box>
              <Typography fontSize={16} fontWeight="bold" mb="0" color={shades.secondary[500]}>
                Знижки
              </Typography>
              <Typography fontSize={12} fontWeight="bold" mb="10px">
                Регулярні акції та знижки
              </Typography>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Box>
  )
}

export default Details
