import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { shades } from '../theme'
import imageWoman from '../assets/header.jpg'
import './Header.css'

const buttonStyle = {
  color: 'white',
  backgroundColor: shades.secondaryRed[400],
  fontSize: '20px',
  fontWeight: 'bold',
}

const badgeStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '160px',
  height: '160px',
  borderRadius: '50%',
  backgroundColor: shades.secondary[400], // You can replace this with your desired color
  color: shades.secondary[100],
  fontWeight: 'bold',
  fontSize: '60px',
}

const Header = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  return (
    <Box
      style={{
        height: '600px',
      }}
    >
      <img
        src={imageWoman}
        style={{
          width: '100%',
          height: '600px',
          objectFit: 'cover',
          backgroundAttachment: 'fixed',
          userSelect: 'none',
        }}
      />
      <Box
        style={{ userSelect: 'none' }}
        color="white"
        padding="30px 10px"
        textAlign="left"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        backgroundColor="rgb(0, 0, 0, 0.6)"
        position="absolute"
        top={0}
        left={0}
        right={0}
        margin={0}
        height="600px"
        width="100%"
      >
        <Box mt={1}>
          <Typography fontWeight="bold" fontSize={isNonMobile ? 42 : 36} mb={4} textAlign={'center'}>
            PARFUM CITY
          </Typography>
          {/* <Typography fontWeight="bold" fontSize={24} textAlign={'center'}>
            ДЕНЬ НЕЙМОВІРНИХ ЗНИЖОК
          </Typography> */}
          <Typography
            mb={2}
            fontWeight="bold"
            fontSize={isNonMobile ? 40 : 28}
            textAlign={'center'}
            style={{ textShadow: '2px 2px 7px #F8C630' }}
          >
            РОЗПРОДАЖ ПАРФУМЕРІЇ ВІД 369 ГРН
          </Typography>

          <Typography fontSize={isNonMobile ? 24 : 16} fontWeight="bold" textAlign={'center'}>
            ТІЛЬКИ СЬОГОДНІ ДО 24:00
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-around">
          <div className="heartbeat" style={badgeStyle}>
            -70%
          </div>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <a
            href="https://rdr.salesdoubler.com.ua/in/offer/1310?aid=103422&dlink=https%3A%2F%2Fparfumcity.com.ua%2Fua"
            target="_self"
            rel="noopener noreferrer"
          >
            <Button id="catalog-link-btn" variant="contained" style={buttonStyle}>
              ПЕРЕЙТИ ДО КАТАЛОГУ
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default Header
