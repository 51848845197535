import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { shades } from '../theme'
import React from 'react'

const containerStyle = {
  position: 'relative',
  display: 'inline-block',
}

const imageStyle = {
  maxWidth: '100%',
  verticalAlign: 'top',
}

const triangleStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  borderLeft: '110px solid transparent',
  borderBottom: '110px solid #e66677',
  //d40758',
  transform: 'rotate(180deg)',
  zIndex: 1,
}

const saleTextStyle = {
  position: 'absolute',
  top: '40px',
  left: '40px',
  transform: 'translate(-50%, -50%) rotate(-45deg)',
  fontSize: 24,
  fontWeight: 'bold',
  color: 'white',
  zIndex: 2,
}

const Item = ({ item, indx }) => {
  const isNonMobile = useMediaQuery('(min-width:900px)')

  const badgeStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: isNonMobile ? 'auto' : '100%',
    padding: '0 10px',
    height: '30px',
    borderRadius: '3px',
    backgroundColor: shades.secondaryRed[400],
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'white',
  }

  const { category, description, offer, image, link, anchor } = item

  return (
    <Box
      id={anchor}
      display="flex"
      alignItems="flex-start"
      flexDirection={isNonMobile ? 'row' : 'column'}
      justifyContent="center"
      width="100%"
      bgcolor={indx % 2 == 0 ? 'white' : ''}
      overflow="hidden"
      p={isNonMobile ? 10 : 2}
      pb={5}
    >
      {indx % 2 == 0 || !isNonMobile ? (
        <div style={containerStyle}>
          <div style={triangleStyle}></div>
          <img
            src={image}
            alt="Tile"
            style={{ width: isNonMobile ? 600 : '100%', height: isNonMobile ? 360 : 'auto', objectFit: 'cover' }}
          />
          <div style={saleTextStyle}>SALE</div>
        </div>
      ) : (
        ''
      )}
      <Box
        display="flex"
        width={isNonMobile ? '700px' : '100%'}
        flexDirection="column"
        justifyContent="space-between"
        minHeight="360px"
        p={1}
        pl={isNonMobile && indx % 2 == 0 ? 5 : 1}
      >
        <div style={{ marginBottom: '16px' }}>
          <Typography variant="h1" fontWeight="bold" color={shades.neutral}>
            {category}
          </Typography>
          <Box margin="10px 0" style={badgeStyle}>
            Тільки Cьогодні ⭐️ Знижки до -70%
          </Box>
          <Typography fontSize={15} mt={1}>
            {description}
          </Typography>
          <Typography fontSize={15} mt={1}>
            {offer}
          </Typography>
        </div>
        <a href={link} target="_self" rel="noopener noreferrer">
          <Button
            id={anchor + '-link-btn'}
            style={{
              marginTop: 'auto',
              color: 'white',
              fontSize: 18,
              fontWeight: 'bold',
              padding: '5px 50px',
              backgroundColor: shades.secondary[500],
              width: isNonMobile ? 'auto' : '100%',
            }}
          >
            Перейти до каталогу
          </Button>
        </a>
      </Box>
      {indx % 2 == 1 && isNonMobile ? (
        <div style={containerStyle}>
          <div style={triangleStyle}></div>
          <img
            src={image}
            alt="Tile"
            style={{ width: isNonMobile ? 600 : '100%', height: isNonMobile ? 360 : 'auto', objectFit: 'cover' }}
          />
          <div style={saleTextStyle}>SALE</div>
        </div>
      ) : (
        ''
      )}
    </Box>
  )
}

export default Item
