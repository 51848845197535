import React from 'react'
import { Box } from '@mui/material'
import Item from '../components/Item'
import menImg from '../assets/tiles/men.jpg'
import womanImg from '../assets/tiles/woman.jpg'
import testImg from '../assets/tiles/test.jpg'

const Dashboard = () => {
  const items = [
    {
      category: 'ЖІНОЧІ ПАРФУМИ',
      description:
        'Запрошуємо ознайомитися з великим асортиментом жіночих парфумів зі знижкою до -70%!',
      offer:
        'Обирайте аромат, який вам до вподоби, та здійснюйте замовлення! Отримайте вигоду з акцією "1+1=3🎁"',
      image: womanImg,
      link: 'https://rdr.salesdoubler.com.ua/in/offer/1310?aid=103422&dlink=https%3A%2F%2Fparfumcity.com.ua%2Fua%2Fcatalog%2Fzhenskie-duhi',
      anchor: 'woman',
    },
    {
      category: 'ЧОЛОВІЧІ ПАРФУМИ',
      description:
        'Найкращі бренди чоловічих парфумів за низькими цінами. Поспішайте перейти до каталогу Парфум Сіті зі знижкою до -70% прямо зараз!',
      offer:
        'Обирайте аромат, який вам до вподоби, та здійснюйте замовлення! Отримайте вигоду з акцією "1+1=3🎁"',
      image: menImg,
      link: 'https://rdr.salesdoubler.com.ua/in/offer/1310?aid=103422&dlink=https%3A%2F%2Fparfumcity.com.ua%2Fua%2Fcatalog%2Fmuzhskie-duhi',
      anchor: 'men',
    }
  ]

  return (
    <Box display="flex" flexDirection="column" alignItems="center" rowGap={0} margin="0">
      {items.map((item, indx) => (
        <Item item={item} key={indx} indx={indx} />
      ))}
    </Box>
  )
}

export default Dashboard
