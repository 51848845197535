import { useTheme } from '@emotion/react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { shades } from '../theme'

function Footer() {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  return (
    <Box padding="50px 0 0 0">
      <Box
        width="90%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap="30px"
        columnGap="clamp(20px, 30px, 40px)"
      >
        <Box width={isNonMobile ? 'clamp(40%, 30%, 40%)' : 'clamp(95%, 95%, 95%)'}>
          <Typography variant="h3" fontWeight="bold" mb="30px" color={shades.secondaryRed[600]}>
            PARFUMCITY
          </Typography>
          <Typography fontSize="14px" mb="30px">
            Кожен представник чарівної або сильної статі мріє виглядати яскраво і неперевершено. У досягненні цієї мети
            надзвичайно корисним помічником стане інтернет-магазин парфумерії та косметики - ParfumCity.
          </Typography>
        </Box>

        <Box width={isNonMobile ? 'clamp(40%, 30%, 40%)' : 'clamp(95%, 95%, 95%)'}>
          <Typography variant="h3" fontWeight="bold" mb="30px">
            Про нас
          </Typography>
          <Typography fontSize="14px" mb="30px">
            Parfum City - це онлайн-магазин, де ви зможете створити неповторний образ за допомогою широкого вибору
            косметичних засобів та парфумів. Ви зможете підкреслити свою природну красу та знайти аромат, який ідеально
            підійде для вираження вашої індивідуальності.
          </Typography>
        </Box>

        <Box width={isNonMobile ? 'clamp(40%, 30%, 40%)' : 'clamp(95%, 95%, 95%)'}>
          <Typography variant="h3" fontWeight="bold" mb="30px">
            Асортимент
          </Typography>
          <Typography fontSize="14px" mb="30px">
            Iнтернет-магазин ParfumCity пропонує великий асортимент товарів, і ви можете легко зробити покупку онлайн та
            отримати своє замовлення всього за декілька днів. Таким чином, ви зможете швидко насолодитися своїми новими
            косметичними засобами і ароматами, які підкреслять вашу неповторність та унікальність.
          </Typography>
        </Box>

        <Box width={isNonMobile ? 'clamp(40%, 30%, 40%)' : 'clamp(95%, 95%, 95%)'}>
          <Typography variant="h3" fontWeight="bold" mb="30px">
            ©perfumcity.com.ua
          </Typography>
          <Typography fontSize="14px" mb="30px">
          Ця сторінка має інформаційний характер і надає інформацію про косметичні засоби та парфуми.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
