import Dashboard from './Dashboard'
import Timer from './Timer'
import Header from './Header'
import Details from './Details'
import { useMediaQuery } from '@mui/material'
import Banners from './Banners'

function Home() {
  const isNonMobile = useMediaQuery('(min-width:900px)')
  return (
    <div className="home">
      <Header />
      {isNonMobile ? <>
      <Timer />
      <Dashboard />
      <Banners />
      <Details />
       </>: <>
      <Timer />
      <Dashboard />
      <Banners />
      <Details />
      </>}
    </div>
  )
}

export default Home
