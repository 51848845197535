import React from 'react'
import banner1 from '../assets/banners/ads.webp'
import banner2 from '../assets/banners/2.webp'
import banner3 from '../assets/banners/3.webp'
import banner4 from '../assets/banners/4.webp'
import { Carousel } from 'react-responsive-carousel'
import { Box } from '@mui/material'

const banners = [banner1]

const Banners = () => {
  return (
    <Box style={{ backgroundColor: 'white' }} p={2}>
      <Box style={{ maxWidth: 1200, margin: '0px auto' }}>
        <Carousel
          overflow="hidden"
          autoPlay={true}
          infiniteLoop={true}
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
          renderItem={(item, props) => (
            <div key={props.key} style={{ borderRadius: '12px', overflow: 'hidden' }}>
              {item}
            </div>
          )}
        >
          {banners.map((banner, index) => (
            <div key={index}>
              <img
                src={banner}
                alt={`Banner ${index + 1}`}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  maxHeight: 300,
                }}
              />
            </div>
          ))}
        </Carousel>
      </Box>
    </Box>
  )
}

export default Banners
